<template>
    <el-dialog
        center
        title="职位申请"
        :visible.sync="dialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="50%">
      <div class="dialog-box">
        <el-form v-model="form" label-width="120px">
          <el-form-item label="姓名" prop="jobName">
            <el-input size="mini" v-model="form.jobName"></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="jobPhone">
            <el-input size="mini" v-model="form.jobPhone"></el-input>
          </el-form-item>
          <el-form-item label="简历" prop="jobUrl">
            <el-upload
                :action="uploadFileUrl"
                :on-error="handleUploadError"
                :on-success="handleUploadSuccess">
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input size="mini" v-model="form.remark"></el-input>
          </el-form-item>
        </el-form>

      </div>
       <el-button slot="footer" type="primary" @click="saveFun">确定</el-button>
  </el-dialog>
</template>
<script>
import { addJob } from '@/api/index';
export default {
    name: 'AddApply',
    data() {
        return {
            uploadFileUrl: process.env.VUE_APP_BASE_API + "/common/upload", // 上传文件服务器地址
            dialog: false,
            form: {
                jobName: "",
                jobPhone: "",
                jobUrl: "",
                remark: "",
                }
        }
    },
    methods: {
        saveFun() {
            addJob(this.form).then(res => {
                this.dialog = false;
                this.$message.success("上传成功, 请耐心等待");
            })
        },
        handleUploadError() {

        },
        handleUploadSuccess(response) {
            console.log(response);
            this.form.jobUrl = response.url;
        },
        showAction() {
            this.dialog = true;
            this.form = {
                jobName: "",
                jobPhone: "",
                jobUrl: "",
                remark: "",
            }
        }
    }
}
</script>
<style lang="scss">
.dialog-box {
  .el-input__inner,.el-textarea__inner {
    color: #333 !important;
    background-color:  white !important;
    border: 1px solid rgba($color: #a3a3ae, $alpha: 0.80) !important;
    border-radius: 5px;
  }
  .el-textarea__inner:focus, .el-input__inner:focus {
    border: 1px solid rgba($color: #a3a3ae, $alpha: 0.80) !important;
  }
}

</style>
