<template>
    <div class="job-detail-page page-p-t">
        <div class="header">
            <div class="">
                <p class="title">{{  detail.recruitName }}</p>
                <p class="detail"><span>{{ detail.recruitLocation }}</span> | <span>发布于 {{ detail.createTime }}</span></p>
            </div>
            <span @click="applyFun">
                <img src="@/assets/img/aboutAsyou/apply.png"/>
            </span>
        </div>
        <div class="content-box">
            <h3>职位描述</h3>
            <p class="sub-title">岗位职责</p>
            <div class="require-content" v-html="detail.recruitResponsibilities"></div>
            <p class="sub-title">岗位要求</p>
            <div class="require-content" v-html="detail.recruitRequirements"></div>
        </div>
        <add-apply ref="apply"></add-apply>
    </div>
</template>
<script>
import { recruitDetail } from '@/api/index.js';
import addApply from './addApply.vue';
export default {  
    name: 'JobDetail',
    components: {
        addApply
    },
    data() {
        return {
            detail: {}
        }
    },
    created() {
        this.getDetail();
    },
    methods: {
        getDetail() {
            recruitDetail(this.$route.query.recruitId).then(res => {
                this.detail = res.data.data;
            })
        },
        applyFun() {

            this.$refs['apply'].showAction();
        }
     }
}
</script>
<style lang="scss">
    .el-input__inner {
        background-color: rgba(240, 236, 236, 0) !important;
        border: 1px solid rgba($color: #3e3e3e, $alpha: 1) !important;
        border-radius: 5px !important;
    }    
    .el-input__inner:focus {
        border: 1px solid rgba($color: #a3a3ae, $alpha: 0.80) !important;
    }
</style>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/mixin.scss";
    .job-detail-page {
        overflow-y: scroll;

        .header {
            margin: 1.24rem 1.28rem 0.64rem 1.28rem;
            display: flex;
            justify-content: space-between;
            padding-bottom: 0.64rem;
            border-bottom: 1px solid rgba($color: #81848B, $alpha: 0.6);

            .title {
                @include font-2;
                letter-spacing: 10px;
            }
            .detail {
                margin-top: 0.21rem;
                @include de-0-16;
                letter-spacing: 2px;
            }
            span {
                img {
                    cursor: pointer;
                    margin-top: 0.5rem;
                    width: 1.94rem;
                    height: 0.59rem;
                }
            }
        }
        .content-box {
            margin: 0.8rem 1.28rem 1.2rem 1.28rem;

            .require-content, .sub-title{
                margin-top: 0.3rem;
                color: rgba($color: #fff, $alpha: 0.8);
                letter-spacing: 3px;
            }

            h3 {
                
                @include font-4;
            }

        }
    }
</style>